
import "./ImageSwiperSlideContent.scss";
import { ImageEntity } from "../data/Entities";
import { useEffect, useState } from "react";
import { fetchScore } from "../data/DataApi";
import URLUtils from "../utils/URLUtils";
import { IonIcon, IonLabel } from "@ionic/react";
import { calendar, star, time } from "ionicons/icons";
import DateUtils from "../utils/DateUtils";

interface ImageSwiperSlideContentProps {
    image: ImageEntity;
}

const ImageSwiperSlideContent: React.FC<ImageSwiperSlideContentProps> = ({ image }) => {
    const [score, setScore] = useState<number>();

    useEffect(() => {
        fetchScore(image.scoreId)
            .then((se) => setScore(se.score));
    }, [image])

    return (
        <div className="imageslide">
            <img src={URLUtils.getImageURL(image.fname)} alt="" />
            <div className="imageslide-text">
                <div className='imageslide-text-entry'>
                    <IonIcon class='pm-element' icon={star} />
                    <IonLabel>{score ? score : "N/A"} points</IonLabel>
                </div>
                <div className='imageslide-text-entry'>
                    <IonIcon class='pm-element' icon={calendar} />
                    <IonLabel>{DateUtils.dateString(image.ts)}</IonLabel>
                </div>
                <div className='imageslide-text-entry'>
                    <IonIcon class='pm-element' icon={time} />
                    <IonLabel>{DateUtils.timeString(image.ts)}</IonLabel>
                </div>
            </div>
        </div>
    );
};

export default ImageSwiperSlideContent;
