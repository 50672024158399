import { IonCard, IonCardContent, IonCardHeader, IonTitle } from "@ionic/react";
import { Line } from 'react-chartjs-2';

import "./ChartsCard.scss";
import { useEffect, useState } from "react";
import { fetchScoresByPlantImagesQuantity } from "../../data/DataApi";
import LoadingIndicatorComponent from "../utils/LoadingIndicatorComponent";
import DateUtils from "../../utils/DateUtils";

import {
    CategoryScale, Chart, LinearScale, LineElement, PointElement, Title, Tooltip, Legend,
} from 'chart.js';
  
Chart.register(
    CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend,
);

interface IScoreDataLineChart {
    labels: string[];
    datasets: {label: string, data: number[]}[];
}

interface ChartsCardProps {
    plant_id: number;
}

const LINECHART_NUM_SCORES: number = 10;

const ChartsCard: React.FC<ChartsCardProps> = ({ plant_id }) => {

    const [scoreData, setScoreData] = useState<IScoreDataLineChart>();

    const lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'bottom' as const,
            }, 
            title: {
                display: false,
                text: "Scores Timeline",
            }
        },
    }

    useEffect(() => {
        fetchScoresByPlantImagesQuantity(plant_id, LINECHART_NUM_SCORES)
            .then(data => data.reverse())
            .then(data => ({labels: data.map(e => DateUtils.dateString(e.ts)),
                datasets: [{
                    label: "Image Score",
                    data: data.map(e => e.score),
                    borderColor: 'rgb(0, 122, 255)',
                    backgroundColor: 'rgba(0, 122, 255, 0.5',
                }],
            }))
            .then(data => setScoreData(data));
    }, [plant_id]);

    return ((scoreData?.datasets[0].data.length !== 0)
        ? <IonCard>
            <IonCardHeader>
                <IonTitle>
                    Scores Timeline
                </IonTitle>
            </IonCardHeader>
            <IonCardContent>
                { scoreData
                    // TODO: Change this basic line chart to a real time series chart
                    ? <Line data={scoreData} options={lineChartOptions} />
                    : <LoadingIndicatorComponent message="Loading timeline data..." /> }
            </IonCardContent>
        </IonCard>
        : <></> );
};

export default ChartsCard;
