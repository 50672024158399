
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import "./ImageSwiperComponent.scss";
import { ImageEntity } from "../data/Entities";
import ImageSwiperSlideContent from "./ImageSwiperSlideContent";

interface ImageSwiperComponentProps {
    images: ImageEntity[] | undefined;
}

const ImageSwiperComponent: React.FC<ImageSwiperComponentProps> = ({ images }) => {

    return (
        <Swiper navigation={true} pagination={{type: 'progressbar'}} modules={[Navigation, Pagination]}>
            {images?.map((img: ImageEntity, idx) => {
                return (
                    <SwiperSlide key={idx}>
                        <ImageSwiperSlideContent image={img} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

export default ImageSwiperComponent;
