import './AuthenticationRouterComponent.scss';

import { Redirect, Route } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import LoginPage from '../../pages/LoginPage';
import { IAuthToken } from '../../utils/AuthenticationUtils';

interface AuthenticationRouterComponentProps {
    setToken: (userToken: IAuthToken|undefined) => void;
}

const AuthenticationRouterComponent: React.FC<AuthenticationRouterComponentProps>
    = ({setToken}) => {
    return (
        <IonReactRouter>
            <IonRouterOutlet>
                <Route exact path="/login">
                    <LoginPage setToken={setToken} />
                </Route>
                <Redirect to="/login" />
            </IonRouterOutlet>
        </IonReactRouter>
      );
};

export default AuthenticationRouterComponent;
