import { IonText } from '@ionic/react';
import './LoadingIndicatorComponent.scss'

interface LoadingIndicatorComponentProps {
    message?: string;
}

const LoadingIndicatorComponent: React.FC<LoadingIndicatorComponentProps> = ({ message }) => {

    return (
        <div className='loading-indicator'>
            <img src='assets/images/loading.png' alt={message?message:"Loading..."} />
            <IonText>{message?message:"Loading..."}</IonText>
        </div>
    );
};

export default LoadingIndicatorComponent;
