import './PlantMarketContainer.scss';

import { IonContent } from '@ionic/react';
import { useEffect, useState } from 'react';

import { fetchPlantsDiscover, fetchPlantsOfUser } from '../data/DataApi';
import PlantMarketElement from './PlantMarketElement';
import useToken from '../utils/AuthenticationUtils';
import { PlantEntity } from '../data/Entities';
import useLoadingState from '../utils/LoadingUtils';
import LoadingOverlayComponent from './utils/LoadingOverlayComponent';

export enum CatalogType {
    MY_PLANTS,
    DISCOVER
}

interface PlantMarketProps {
    catalog_type: CatalogType;
}

export const PlantMarketContainer: React.FC<PlantMarketProps> = ({ catalog_type }) => {

    const [plants, setPlants] = useState<PlantEntity[]>([]);
    const {token} = useToken();
    const {isLoading, setIsLoading} = useLoadingState();

    useEffect(() => {
        if(catalog_type === CatalogType.MY_PLANTS) {
            setIsLoading(true);
            fetchPlantsOfUser(token.userId).then(data => setPlants(data))
                .then(() => setIsLoading(false));
        }
        else if(catalog_type === CatalogType.DISCOVER) {
            setIsLoading(true);
            fetchPlantsDiscover(token.userId).then(data => setPlants(data))
                .then(() => setIsLoading(false));
        }
    }, [catalog_type, token, setIsLoading])

    return (
        <IonContent>
            <div className='pm-container'>
                {plants
                    .map((plant, index) => {
                        return(
                            <PlantMarketElement source_plant={plant} key={index}></PlantMarketElement>
                        )
                    })
                }
                <LoadingOverlayComponent is_loading={isLoading} />
            </div>
        </IonContent>
    );
}
