import { IonButton, IonLabel, IonModal } from '@ionic/react';
import { ReactNode, useEffect, useState } from 'react';

import './PlantModificationForm.scss';
import {
    IError,
    IField,
    getValues,
    resetValues,
    useCategorySelectionField,
    usePlantModificationFields,
    validateForm
} from '../utils/FormUtils';
import FormField from './FormField';
import { fetchCategories, updatePlant } from '../data/DataApi';
import { CategoryCreationForm } from './CategoryCreationForm';
import { CategoryEntity, PlantEntity } from '../data/Entities';
import ModalToolbarComponent from './toolbars/ModalToolbarComponent';
import useModalState from '../utils/ModalUtils';
import useLoadingState from '../utils/LoadingUtils';
import LoadingOverlayComponent from './utils/LoadingOverlayComponent';
import HierarchicalSearchSelectModal from './HierarchicalSearchSelectModal';

interface PlantModificationFormProps {
    plant: PlantEntity;
    callback?: () => void;
}

export const PlantModificationForm: React.FC<PlantModificationFormProps>
    = ({ plant, callback }) => {

    const {isLoading, setIsLoading} = useLoadingState();

    const updateCategoryField = (event: any) => {
        categoryField.input.state.value = event.target.value;
    }

    const modifyPlant = () => {
        const errors = validateForm([categoryField, ...fields]);
        setErrors(errors);

        if(errors.length === 0) {
            setIsLoading(true);
            updatePlant(plant.id, getValues([categoryField, ...fields]))
                .then(() => resetValues(fields))
                .then(() => {
                    if(callback) {
                        callback();
                    }
                })
                .then(() => setIsLoading(false));
        }
    }

    const[categoryField, setCategoryField] = useState<IField>(
        useCategorySelectionField(updateCategoryField, plant.categoryId));
    const fields = usePlantModificationFields(plant, modifyPlant);
    const [errors, setErrors] = useState<IError[]>();

    const newCatModalState = useModalState('newCat');

    const [categories, setCategories] = useState<CategoryEntity[]>();

    useEffect(() => {
        newCatModalState.closeOnBackButton();

        // Set the select options for the category selector
        fetchCategories().then((data) => {
            setCategories(data);
            let categoryField_copy = Object.assign({}, categoryField); // shallow copy the field
            categoryField_copy.input.options = // assign available options
                data?.map(cat => {return({value: cat.id, label: cat.name, superValue: cat.superCategoryId})});
            setCategoryField(categoryField_copy);
        });
    }, [newCatModalState.useEffectParam]);

    const categoryCreationHeader: ReactNode = (
        <>
            <div className='header'>
                <IonLabel color="medium">Select the category from the options below or </IonLabel>
                <IonButton onClick={() => newCatModalState.setIsOpen(true)}>
                    Create new Category
                </IonButton>
            </div>
            <IonModal isOpen={newCatModalState.isOpen} onDidDismiss={() => newCatModalState.setIsOpen(false)}>
                <ModalToolbarComponent title='Create Plant Category'
                    closeFunction={() => newCatModalState.setIsOpen(false)} />
                <CategoryCreationForm categories={categories}
                    callback={() => newCatModalState.setIsOpen(false)} />
            </IonModal>
        </>
    );

    return (
        <>
            <div className='formcontainer'>
                <HierarchicalSearchSelectModal field={categoryField} header={categoryCreationHeader} />
            </div>
            {fields?.map((field, index) => {
                return(
                    <div className='formcontainer' key={index}>
                        <FormField field={field} errors={errors} key={index} />
                    </div>
                )
            })}
            <div className='formcontainer'>
                <IonButton className='createbutton' onClick={modifyPlant}>Save Changes</IonButton>
            </div>
            <LoadingOverlayComponent is_loading={isLoading} />
        </>
    );
}
