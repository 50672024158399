import './PlantCreationPage.css';

import { IonPage } from '@ionic/react';

import ToolbarComponent from '../components/toolbars/ToolbarComponent';
import { PlantCreationForm } from '../components/PlantCreationForm';

const PlantCreationPage: React.FC = () => {
  return (
    <IonPage>
      <ToolbarComponent title="Create Plant"></ToolbarComponent>
      <PlantCreationForm></PlantCreationForm>
    </IonPage>
  );
};

export default PlantCreationPage;
