
class URLUtils {
    static readonly BASE_URL: string = "https://plantit.ywcb.org:8080/";
    static readonly CATEGORY_SUFFIX: string = "category/";
    static readonly PLANT_SUFFIX: string = "plant/";
    static readonly IMAGE_SUFFIX: string = "image/";
    static readonly SCORE_SUFFIX: string = "score/";
    static readonly COMMENT_SUFFIX: string = "comment/";
    static readonly USER_SUFFIX: string = "user/";

    static getImageURL(fname: string|undefined): string {
        if(!fname) {
            return "";
        }
        return `${this.BASE_URL}${this.IMAGE_SUFFIX}retrieve?fname=${fname}`;
    }

    static appendSearchParam(navigation: any, location: any, param_name: string, param_value: string) {
        const url_params = new URLSearchParams(location.location?.search);
        url_params.append(param_name, param_value);
        navigation.push({search: url_params.toString()})
    }
}

export default URLUtils;
