import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonSearchbar,
    IonText,
    IonToolbar } from '@ionic/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { IError, IField, IFieldOption } from '../utils/FormUtils';

import './HierarchicalSearchSelectModal.scss';
import { IonSearchbarCustomEvent } from '@ionic/core';
import ModalToolbarComponent from './toolbars/ModalToolbarComponent';
import useModalState from '../utils/ModalUtils';
import { chevronDownOutline } from 'ionicons/icons';
import HierarchicalSelectOption from './HierarchicalSelectOption';

interface HierarchicalSearchSelectModalProps {
    field: IField;
    header?: ReactNode;
    errors?: IError[];
}

export const HierarchicalSearchSelectModal: React.FC<HierarchicalSearchSelectModalProps>
    = ({ field, header, errors }) => {
    const error: IError|undefined = errors?.filter(e => (e.id === field.id))[0];

    // TODO: Fix the problem of closing nested modals of the same type.
    // NOTE: Including the field identifier in the modal state name is just a quick fix.
    const selectModalState = useModalState(`select${field.id}`);

    const [filteredOptions, setFilteredOptions]
        = useState<IFieldOption[]|undefined>(field.input.options);

    const [currentSelection, setCurrentSelection] = useState<string|number>(0);

    const submitSelection = () => {
        if(field.input.props.onIonChange)
            field.input.props.onIonChange(currentSelection);
        selectModalState.setIsOpen(false);
    }

    const handleSearchbarInput = (event: IonSearchbarCustomEvent<KeyboardEvent>) => {
        filterSearchSpace(event.target.value);
    }

    const filterSearchSpace = (searchQuery: string|null|undefined) => {
        if(!searchQuery) {
            setFilteredOptions(field.input.options);
        } else {
            const searchString: string = searchQuery.toLowerCase();
            setFilteredOptions(field.input.options?.filter((option) => {
                return option.label.toLowerCase().includes(searchString);
            }));
        }
    }

    useEffect(() => {
        selectModalState.closeOnBackButton();
        setFilteredOptions(field.input.options);
    }, [selectModalState.useEffectParam, field]);

    return (
        <>
        <div className='formfield-div'>
            <IonItem color="light" onClick={() => selectModalState.setIsOpen(true)}>
                <IonLabel position="stacked" color={error ? "danger" : "primary"}>
                    {field.label}
                    {error
                        && <p color="primary">{error.message}</p>}
                </IonLabel>
                <div className='selection'>
                    <IonText color={field.input.state.value ? "dark" : "medium"} class='selection'>
                        {field.input.state.value
                            ? field.input.options?.find(
                                (opt) => opt.value === field.input.state.value)?.label
                            : field.input.props.placeholder}
                    </IonText>
                    <IonIcon icon={chevronDownOutline} />
                </div>
            </IonItem>
        </div>
        <IonModal isOpen={selectModalState.isOpen}
            onDidDismiss={() => selectModalState.setIsOpen(false)}>
            <IonHeader>
                <ModalToolbarComponent title={field.input.props.placeholder}
                    closeFunction={() => selectModalState.setIsOpen(false)} />
                {header}
                <IonToolbar>
                    <IonSearchbar onIonInput={handleSearchbarInput} />
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {(field.input.options?.length === filteredOptions?.length)
                    ? <HierarchicalSelectOption displayOption={filteredOptions?.find(val => (val.value === 0))}
                        allOptions={field.input.options} state={currentSelection} setState={setCurrentSelection} />
                    : filteredOptions?.map(opt => {
                        return (<HierarchicalSelectOption displayOption={opt} allOptions={field.input.options}
                            state={currentSelection} setState={setCurrentSelection} />);})
                }
            </IonContent>
            <IonFooter>
                <IonItem color="medium">
                    <IonLabel>{field.input.options?.find(o => (o.value === currentSelection))?.label}</IonLabel>
                </IonItem>
                <IonButton onClick={() => submitSelection()}>
                    Select
                </IonButton>
            </IonFooter>
        </IonModal>
        </>
    )
}

export default HierarchicalSearchSelectModal;
