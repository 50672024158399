import URLUtils from "../utils/URLUtils"
import { CategoryEntity, CommentEntity, ImageEntity, PlantEntity, ScoreEntity, UserEntity } from "./Entities";


const handleError = (reason: any) => {
  console.log("ERROR: TODO: predefine a general error message for this");
  console.log(reason);
}

export const fetchCategories = (): Promise<CategoryEntity[]> => {
  const categories = fetch(
    `${URLUtils.BASE_URL}${URLUtils.CATEGORY_SUFFIX}/get/all`,
    {method: 'GET', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return categories;
}

export const fetchPlant = (plantId: number): Promise<PlantEntity> => {
  const plant = fetch(
    `${URLUtils.BASE_URL}${URLUtils.PLANT_SUFFIX}/get?id=${plantId}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return plant;
}

export const fetchPlantsOfUser = (userId: number): Promise<PlantEntity[]> => {
  const plants = fetch(
    `${URLUtils.BASE_URL}${URLUtils.PLANT_SUFFIX}/get/byuser?userId=${userId}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return plants;
}

export const fetchPlantsDiscover = (userId: number): Promise<PlantEntity[]> => {
  const plants = fetch(
    `${URLUtils.BASE_URL}${URLUtils.PLANT_SUFFIX}/discover?userId=${userId}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return plants;
}

export const fetchImageEntities = (plantId: number): Promise<ImageEntity[]> => {
  const images = fetch(
    `${URLUtils.BASE_URL}${URLUtils.IMAGE_SUFFIX}/get?plantId=${plantId}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return images;
}

export const fetchNewestImageEntityOfPlant = (plantId: number): Promise<ImageEntity> => {
  const image = fetch(
    `${URLUtils.BASE_URL}${URLUtils.IMAGE_SUFFIX}/get/newest/ofplant?plantId=${plantId}`,
    {method: 'POST', headers: {},}
  )
  .then((response: Response) => {
    if(response.ok) {
      if(response.status === 204) { // i.e., no image available (204: NO CONTENT)
        return undefined;
      }
      return response.json();
    }
  })
  .catch((err) => {
    handleError(err);
  });

  return image;
}

export const fetchCategory = (categoryId: number): Promise<CategoryEntity> => {
  const category = fetch(
    `${URLUtils.BASE_URL}${URLUtils.CATEGORY_SUFFIX}/get?id=${categoryId}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return category;
}

export const fetchScore = (scoreId: number): Promise<ScoreEntity> => {
  const score = fetch(
    `${URLUtils.BASE_URL}${URLUtils.SCORE_SUFFIX}/get?scoreId=${scoreId}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return score;
}

export const fetchScoresByPlantImages = (plantId: number): Promise<ScoreEntity[]> => {
  const scores = fetch(
    `${URLUtils.BASE_URL}${URLUtils.SCORE_SUFFIX}/get/byplantimages?plantId=${plantId}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return scores;
}

export const fetchScoresByPlantImagesQuantity = (plantId: number, quantity: number): Promise<ScoreEntity[]> => {
  const scores = fetch(
    `${URLUtils.BASE_URL}${URLUtils.SCORE_SUFFIX}/get/byplantimages/quantity?plantId=${plantId}&quantity=${quantity}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return scores;
}

export const fetchComments = (plantId: number): Promise<CommentEntity[]> => {
  const comments = fetch(
    `${URLUtils.BASE_URL}${URLUtils.COMMENT_SUFFIX}/get/plant?plantId=${plantId}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return comments;
}

export const fetchUserByName = (username: string): Promise<UserEntity> => {
  const user = fetch(
    `${URLUtils.BASE_URL}${URLUtils.USER_SUFFIX}/get/byname?username=${username}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });

  return user;
}

export const triggerScoreRefresh = (plantId: number): Promise<ScoreEntity> => {
  const response = fetch(
    `${URLUtils.BASE_URL}${URLUtils.SCORE_SUFFIX}/plant/refresh`
      + `?plantId=${plantId}`,
      {method: 'POST', headers: {},}
  )
  .then(response => response.json())
  .catch((err) => {
    handleError(err);
  });
  return response;
}

export const pushPlant = (values: Map<string, any>, userId: number): Promise<boolean|void> => {
  const response = fetch(
    `${URLUtils.BASE_URL}${URLUtils.PLANT_SUFFIX}/create`
      + `?name=${values.get("name")}`
      + `&categoryId=${values.get("category")}`
      + `&latitude=0` // TODO: set correct location
      + `&longitude=0`
      + `&userId=${userId}`,
      {method: 'POST', headers: {},}
  )
  .then(response => response.ok)
  .catch((err) => {
    handleError(err);
  });
  return response;
}

export const pushCategory = (values: Map<string, any>): Promise<boolean|void> => {
  const response = fetch(
    `${URLUtils.BASE_URL}${URLUtils.CATEGORY_SUFFIX}/create`
      + `?name=${values.get("name")}`
      + (values.get('description') ? `&description=${values.get("description")}` : '')
      + (values.get('water_demand') ? `&waterDemand=${values.get("water_demand")}` : '')
      + (values.get('watering_frequency') ? `&wateringFrequency=${values.get("watering_frequency")}` : '')
      + `&superCategoryId=${values.get("super_category")}`,
      {method: 'POST', headers: {},}
  )
  .then(response => response.ok)
  .catch((err) => {
    handleError(err);
  });
  return response;
}

export const pushComment = (plantId: number, values: Map<string, any>, userId: number): Promise<boolean|void> => {
  const response = fetch(
    `${URLUtils.BASE_URL}${URLUtils.COMMENT_SUFFIX}/create/plant`
      + `?plantId=${plantId}`
      + `&text=${values.get("comment")}`
      + `&userId=${userId}`,
      {method: 'POST', headers: {},}
  )
  .then(response => response.ok)
  .catch((err) => {
    handleError(err);
  });
  return response;
}

export const uploadImage = (image: string|Blob, plantId: number) => {
  var data = new FormData();
  data.append("image", image);

  const response = fetch(
    `${URLUtils.BASE_URL}${URLUtils.IMAGE_SUFFIX}/upload`
      + `?plantId=${plantId}`,
      {method: 'POST', headers: {}, body: data},
  )
  .then(response => response.ok)
  .catch((err) => {
    handleError(err);
  });
  return response;
}

export const updatePlant = (plantId: number, values: Map<string, any>): Promise<boolean|void> => {
  const response = fetch(
    `${URLUtils.BASE_URL}${URLUtils.PLANT_SUFFIX}/modify`
      + `?id=${plantId}`
      + `&name=${values.get("name")}`
      + `&categoryId=${values.get("category")}`
      + `&latitude=0` // TODO: set correct location
      + `&longitude=0`,
      {method: 'POST', headers: {},}
  )
  .then(response => response.ok)
  .catch((err) => {
    handleError(err);
  });
  return response;
}

export const deletePlant = (plantId: number): Promise<boolean|void> => {
  const response = fetch(
    `${URLUtils.BASE_URL}${URLUtils.PLANT_SUFFIX}/delete?id=${plantId}`,
    {method: 'POST', headers: {},}
  )
  .then(response => response.ok)
  .catch((err) => {
    handleError(err);
  });

  return response;
}
