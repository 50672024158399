import { IonButton, IonLabel, IonPage } from '@ionic/react';
import { useState } from 'react';
import FormField from '../components/FormField';
import ToolbarComponent from '../components/toolbars/ToolbarComponent';
import { IAuthToken, verifyLoginCredentials } from '../utils/AuthenticationUtils';
import { useLoginFields, validateForm, IError, IField, getField, resetValues } from '../utils/FormUtils';
import './LoginPage.scss';

// Thanks to https://github.com/alanmontgomery/ionic-react-login

interface LoginPageProps {
    setToken: (userToken: IAuthToken|undefined) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({setToken}) => {
    const login = async () => {
        setLoginFailed(false); // reset the login failed flag

        const errors = validateForm(fields);
        setErrors(errors);

        // TODO: authenticate with the server, only set token if authentication was successful
        if(errors.length === 0) {
            if(await verifyLoginCredentials(fields, setToken)) {
                resetValues(fields);
            }
            else {
                setLoginFailed(true);
                resetValues([getField(fields, "password")]);
            }
        }
    }

    const fields: IField[] = useLoginFields(login);
    const [errors, setErrors] = useState<IError[]>();
    const [loginFailed, setLoginFailed] = useState<boolean>();

    return (
        <IonPage>
            <ToolbarComponent title='Login'></ToolbarComponent>
            <div className='login-div'>
                {loginFailed
                    ? <IonLabel color='warning'>Username or Password was incorrect</IonLabel>
                    : <></>
                }
                {fields
                    .map((field, index) => {
                        return(
                            <FormField field={field} errors={errors} key={index} />
                        )
                    })
                }
                <IonButton className='login-button' onClick={login}>Login</IonButton>
            </div>
        </IonPage>
    );
}

export default LoginPage;
