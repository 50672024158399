import { IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonText } from '@ionic/react';
import React from 'react';
import { IError, IField } from '../utils/FormUtils';

import './FormField.scss';

// Thanks to https://github.com/alanmontgomery/ionic-react-login

interface FormFieldProps {
    field: IField;
    errors?: IError[];
}

export const FormField: React.FC<FormFieldProps> = ({ field, errors }) => {
    const error = errors?.filter(e => (e.id === field.id))[0];

    const getInputField = (field: IField) => {
        if(field.input.props.type === "text" || field.input.props.type === "password"
            || field.input.props.type === "number") {
            return (
                <IonInput type={field.input.props.type} placeholder={field.input.props.placeholder}
                    onIonChange={field.input.props.onIonChange} onKeyDown={field.input.props.onKeyDown} {...field.input.state} />
            );
        }
        else if(field.input.props.type === "select") {
            return (
                <IonSelect placeholder={field.input.props.placeholder} onIonChange={field.input.props.onIonChange}
                    onKeyDown={field.input.props.onKeyDown} interface='action-sheet'>
                    {field.input.options?.map(({value, label}, index) => {
                        return (
                            <IonSelectOption value={value} key={index}>{label}</IonSelectOption>
                        )
                    })}
                </IonSelect>
            );
        }
    }

    const getPrefixText = (field: IField) => {
        return field.appearance?.prefix
            ? ( <IonText class='formfield-prefix'>{field.appearance?.prefix}</IonText> )
            : null;
    }

    const getSuffixText = (field: IField) => {
        return field.appearance?.suffix
            ? ( <IonText class='formfield-suffix'>{field.appearance?.suffix}</IonText> )
            : null;
    }

    return (
        <div className='formfield-div'>
            <IonItem color="light">
                <IonLabel position='stacked' color={error ? "danger" : "primary"}>
                    {field.label}
                    {error && <p color="primary">{error.message}</p>}
                </IonLabel>
                <div>
                { getPrefixText(field) }
                { getInputField(field) }
                { getSuffixText(field) }
                </div>
            </IonItem>
        </div>
    )
}

export default FormField;
