import './LoadingOverlayComponent.scss'

import { IonLoading } from '@ionic/react';

interface LoadingOverlayComponentProps {
    is_loading: boolean;
    message?: string;
}

const LoadingOverlayComponent: React.FC<LoadingOverlayComponentProps> = ({ is_loading, message }) => {

    return (
        <>
            <IonLoading message={message?message:"Loading..."} isOpen={is_loading} />
        </>
    );
};

export default LoadingOverlayComponent;
