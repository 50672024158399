import { IonContent, IonPage } from '@ionic/react';
import {CatalogType, PlantMarketContainer} from '../components/PlantMarketContainer';
import ToolbarComponent from '../components/toolbars/ToolbarComponent';
import './OverviewPage.css';

const OverviewPage: React.FC = () => {
  return (
    <IonPage>
      <ToolbarComponent title="Overview"></ToolbarComponent>
      <IonContent>
        <PlantMarketContainer catalog_type={CatalogType.MY_PLANTS}></PlantMarketContainer>
      </IonContent>
    </IonPage>
  );
};

export default OverviewPage;
