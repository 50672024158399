import { createAnimation, IonCard, IonCardContent, IonCardHeader, IonIcon, IonItem, IonList, IonText, IonTitle } from "@ionic/react";
import type { Animation } from "@ionic/react";
import { useEffect, useRef, useState } from "react";

import { CategoryEntity, PlantEntity, ScoreEntity } from "../../data/Entities";
import "./FactsCard.scss";
import { fetchCategory, fetchScore, fetchScoresByPlantImagesQuantity } from "../../data/DataApi";
import { remove, trendingDown, trendingUp } from "ionicons/icons";

interface FactsCardProps {
    plant: PlantEntity;
    category: CategoryEntity | undefined;
}

const FactsCard: React.FC<FactsCardProps> = ({ plant, category }) => {

    const [score, setScore] = useState<ScoreEntity>();
    const [secondLastScore, setSecondLastScore] = useState<ScoreEntity>();
    const [superCategory, setSuperCategory] = useState<CategoryEntity>();

    const [scoreChangeDiff, setScoreChangeDiff] = useState<number>(0);
    const scoreChangeArrows = useRef<HTMLDivElement | null>(null);
    const scoreIncreaseAnimation = useRef<Animation | null>(null);
    const scoreDecreaseAnimation = useRef<Animation | null>(null);

    useEffect(() => {
        if(!scoreIncreaseAnimation.current || !scoreDecreaseAnimation.current) {
            scoreIncreaseAnimation.current = createAnimation()
                .addElement(scoreChangeArrows.current!)
                .duration(1000)
                .iterations(3)
                .keyframes([
                    { offset: 0, marginTop: '0px', opacity: '0.2', visibility: 'visible' },
                    { offset: 0.5, marginTop: '-50px', opacity: '1' },
                    { offset: 1, marginTop: '-100px', opacity: '0.2' },
                ]);
            scoreDecreaseAnimation.current = createAnimation()
                .addElement(scoreChangeArrows.current!)
                .duration(1000)
                .iterations(3)
                .keyframes([
                    { offset: 0, marginTop: '0px', opacity: '0.2', visibility: 'visible' },
                    { offset: 0.5, marginTop: '50px', opacity: '1' },
                    { offset: 1, marginTop: '100px', opacity: '0.2' },
                ]);
        }

        fetchScoresByPlantImagesQuantity(plant.id, 2).then(data => setSecondLastScore(data.at(1)));
        fetchScore(plant.lastScoreId).then(data => {
            if(score && data && score.id !== data.id) {
                setScoreChangeDiff(data.score - score.score);
                if(data.score >= score.score)
                    scoreIncreaseAnimation.current?.play();
                else
                    scoreDecreaseAnimation.current?.play();
            }
            setScore(data);
        });
        if(category?.superCategoryId) {
            fetchCategory(category?.superCategoryId).then(data => setSuperCategory(data));
        }
    }, [plant, category])

    return (
        <>
            <IonCard>
                <IonCardHeader>
                    <IonTitle>
                        Facts
                    </IonTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonList class="factscard">
                        <IonItem hidden={superCategory?.name === undefined}>
                            <IonText class="fc-description">Supercategory: </IonText>
                            <IonText>{superCategory?.name}</IonText>
                        </IonItem>
                        <IonItem hidden={category?.waterDemand === 0 || category?.waterDemand === undefined}>
                            <IonText class="fc-description">Water demand: </IonText>
                            <IonText>{category?.waterDemand} millilitre</IonText>
                        </IonItem>
                        <IonItem hidden={category?.wateringFrequency === 0 || category?.wateringFrequency === undefined}>
                            <IonText class="fc-description">Watering frequency: </IonText>
                            <IonText>{category?.wateringFrequency === 1 ? "Daily"
                                : (category?.wateringFrequency === 7 ? "Weekly"
                                : "Every " + category?.wateringFrequency + " days")}</IonText>
                        </IonItem>
                        <IonItem hidden={score === undefined}>
                            <IonText class="fc-description">Score: </IonText>
                            <div>
                                { score && secondLastScore && <IonIcon icon={ (score.score === secondLastScore.score) ? remove
                                    : ((score.score < secondLastScore.score) ? trendingDown : trendingUp) }
                                    color={ score.score === secondLastScore.score ? "warning"
                                        : (score.score < secondLastScore.score) ? "danger" : "success"} /> }
                                <IonText>{score?.score} points</IonText>
                            </div>
                        </IonItem>
                    </IonList>
                </IonCardContent>
            </IonCard>
            <div className="fc-scorechange">
                <div className="fc-scorechange-arrows" ref={scoreChangeArrows}>
                    <IonText color={scoreChangeDiff >= 0 ? "success" : "danger"}>
                        <h1>{scoreChangeDiff >= 0 ? "+" : ""}{scoreChangeDiff} points</h1>
                    </IonText>
                    <div className="fc-scorechange-arrow">
                        <span style={scoreChangeDiff >= 0
                            ? {'transform': 'rotate(45deg)', 'backgroundColor': 'var(--ion-color-success, #000000)'}
                            : {'transform': 'rotate(-45deg)', 'backgroundColor': 'var(--ion-color-danger, #000000)'}}></span>
                        <span style={scoreChangeDiff >= 0
                            ? {'transform': 'rotate(-45deg)', 'backgroundColor': 'var(--ion-color-success, #000000)'}
                            : {'transform': 'rotate(45deg)', 'backgroundColor': 'var(--ion-color-danger, #000000)'}}></span>
                    </div>
                    <IonText color={scoreChangeDiff >= 0 ? "success" : "danger"}>
                        <h1>{scoreChangeDiff >= 0 ? "+" : ""}{scoreChangeDiff} points</h1>
                    </IonText>
                    <div className="fc-scorechange-arrow">
                        <span style={scoreChangeDiff >= 0
                            ? {'transform': 'rotate(45deg)', 'backgroundColor': 'var(--ion-color-success, #000000)'}
                            : {'transform': 'rotate(-45deg)', 'backgroundColor': 'var(--ion-color-danger, #000000)'}}></span>
                        <span style={scoreChangeDiff >= 0
                            ? {'transform': 'rotate(-45deg)', 'backgroundColor': 'var(--ion-color-success, #000000)'}
                            : {'transform': 'rotate(45deg)', 'backgroundColor': 'var(--ion-color-danger, #000000)'}}></span>
                    </div>
                    <IonText color={scoreChangeDiff >= 0 ? "success" : "danger"}>
                        <h1>{scoreChangeDiff >= 0 ? "+" : ""}{scoreChangeDiff} points</h1>
                    </IonText>
                    <div className="fc-scorechange-arrow">
                        <span style={scoreChangeDiff >= 0
                            ? {'transform': 'rotate(45deg)', 'backgroundColor': 'var(--ion-color-success, #000000)'}
                            : {'transform': 'rotate(-45deg)', 'backgroundColor': 'var(--ion-color-danger, #000000)'}}></span>
                        <span style={scoreChangeDiff >= 0
                            ? {'transform': 'rotate(-45deg)', 'backgroundColor': 'var(--ion-color-success, #000000)'}
                            : {'transform': 'rotate(45deg)', 'backgroundColor': 'var(--ion-color-danger, #000000)'}}></span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FactsCard;
