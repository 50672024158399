import imageCompression from 'browser-image-compression';

class ImageUtils {
    static IMG_COMPRESS_OPTIONS = {
        "maxSizeMB": 1,
        "maxWidthOrHeight": 1920,
        "useWebWorker": true,
        "preserveExif": true,
        "fileType": 'image/jpeg',
        "initialQuality": 0.9
    }

    static getBase64FromImageUrl = async (imageUrl: string): Promise<File> => {
        const imgFile = fetch(imageUrl)
            .then((response: Response) => response.blob())
            .then((img: Blob) => this.resizeImage(img));
        return imgFile;
    }

    static resizeImage = (img: Blob): Promise<File> => {
        const imgFile: File = new File([img], "img.jpg", {type: img.type});
        const compressedImgFile: Promise<File> = imageCompression(imgFile, this.IMG_COMPRESS_OPTIONS);
        return compressedImgFile;
    }
}

export default ImageUtils;
