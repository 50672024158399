import { IonCard, IonCardContent, IonCardHeader, IonTitle } from "@ionic/react";
import 'swiper/scss';

import "./ImagesCard.scss";
import ImageSwiperComponent from "../ImageSwiperComponent";
import { ImageEntity } from "../../data/Entities";
import { useEffect, useState } from "react";
import { fetchImageEntities } from "../../data/DataApi";
import LoadingIndicatorComponent from "../utils/LoadingIndicatorComponent";

interface ImagesCardProps {
    plant_id: number;
}

const ImagesCard: React.FC<ImagesCardProps> = ({ plant_id }) => {

    const [images, setImages] = useState<ImageEntity[]>();

    useEffect(() => {
        fetchImageEntities(plant_id)
            .then(data => setImages(data));
    }, [plant_id]);

    return ((images?.length !== 0)
        ? <IonCard>
            <IonCardHeader>
                <IonTitle>
                    Images
                </IonTitle>
            </IonCardHeader>
            <IonCardContent>
                { images
                    ? <ImageSwiperComponent images={images} />
                    : <LoadingIndicatorComponent message="Loading images..." /> }
            </IonCardContent>
        </IonCard>
        : <></> );
};

export default ImagesCard;
