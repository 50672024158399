import './PlantMarketElement.scss'

import {
    IonAvatar,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonLabel,
    IonModal,
    IonRow,
} from '@ionic/react';
import { star, timer, water } from 'ionicons/icons';
import { useEffect, useState } from 'react';

import { fetchCategory, fetchNewestImageEntityOfPlant, fetchPlant, fetchScore } from '../data/DataApi';
import { PlantEntity, ImageEntity, CategoryEntity, ScoreEntity } from '../data/Entities'
import URLUtils from '../utils/URLUtils';
import PlantDetailComponent from './PlantDetailComponent';
import ModalToolbarComponent from './toolbars/ModalToolbarComponent';
import useModalState from '../utils/ModalUtils';

interface PlantMarketElementProps {
    source_plant: PlantEntity;
}

const PlantMarketElement: React.FC<PlantMarketElementProps> = ({ source_plant }) => {

    const [plant, setPlant] = useState<PlantEntity>(source_plant);
    const [category, setCategory] = useState<CategoryEntity>();
    const [score, setScore] = useState<ScoreEntity>();
    const [image, setImage] = useState<ImageEntity>();
    const detailModalState = useModalState('detail');

    useEffect(() => {
        detailModalState.closeOnBackButton();

        fetchPlant(source_plant.id)
            .then(data => {
                if(data) {
                    setPlant(data);
                }
                else if(!plant.isDeleted) { // only the first time, otherwise we close all detail modals repeatedly
                    detailModalState.setIsOpen(false);
                    plant.isDeleted = true; // set the isDeleted flag to mark the component as hidden
                }
            });
        fetchCategory(plant.categoryId).then(data => setCategory(data));
        fetchScore(plant.lastScoreId).then(data => setScore(data));
        fetchNewestImageEntityOfPlant(plant.id).then(data => setImage(data));
    }, [detailModalState.useEffectParam, source_plant, plant.id, plant.categoryId,
        plant.lastScoreId]);

    return (
        <>
            <IonCard class='pm-element' hidden={plant.isDeleted} onClick={() => detailModalState.setIsOpen(true)}>
                <IonCardHeader>
                    <IonCardTitle class='pm-element'>{plant.name}</IonCardTitle>
                    <IonCardSubtitle class='pm-element'>{category?.name}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonRow class='pm-element'>
                        <IonCol>
                            <IonAvatar>
                                <img src={URLUtils.getImageURL(image?.fname)}
                                    alt={`Plant ${plant.name}`} />
                            </IonAvatar>
                        </IonCol>
                        <IonCol>
                            <IonRow class='pm-element'>
                                <div className='pme-icon'>
                                    <IonIcon class='pm-element' icon={star} />
                                </div>
                                <IonLabel class='pm-element'>{score?.score} pts</IonLabel>
                            </IonRow>
                            <IonRow class='pm-element'>
                                <div className='pme-icon'>
                                    <IonIcon class='pm-element' icon={water} />
                                </div>
                                <IonLabel class='pm-element'>{category?.waterDemand === 0 ? "N/A"
                                    : category?.waterDemand + " ml"}</IonLabel>
                            </IonRow>
                            <IonRow class='pm-element'>
                                <div className='pme-icon'>
                                    <IonIcon class='pm-element' icon={timer} />
                                </div>
                                <IonLabel class='pm-element'>{category?.wateringFrequency === 0 ? "N/A"
                                    : (category?.wateringFrequency === 1 ? "Daily"
                                    : (category?.wateringFrequency === 7 ? "Weekly"
                                    : category?.wateringFrequency + " days"))}</IonLabel>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
            <IonModal isOpen={detailModalState.isOpen} onDidDismiss={() => detailModalState.setIsOpen(false)}>
                <ModalToolbarComponent title={plant.name} subtitle={category?.name}
                    closeFunction={() => detailModalState.setIsOpen(false)} />
                <PlantDetailComponent plant={plant} category={category} />
            </IonModal>
        </>
    );
};

export default PlantMarketElement;
