
class DateUtils {
    static dateTimeString(ts: string): string {
        return (new Date(ts)).toLocaleString();
    }

    static dateString(ts: string): string {
        return (new Date(ts)).toLocaleDateString();
    }

    static timeString(ts: string): string {
        return (new Date(ts)).toLocaleTimeString();
    }
}

export default DateUtils;
