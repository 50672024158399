import { IonContent } from "@ionic/react";

import { CategoryEntity, PlantEntity } from "../data/Entities";
import "./PlantDetailComponent.scss";
import DescriptionCard from "./plantdetailcards/DescriptionCard";
import FactsCard from "./plantdetailcards/FactsCard";
import CommentsCard from "./plantdetailcards/CommentsCard";
import ImagesCard from "./plantdetailcards/ImagesCard";
import ToolsCard from "./plantdetailcards/ToolsCard";
import useToken from "../utils/AuthenticationUtils";
import ChartsCard from "./plantdetailcards/ChartsCard";

interface PlantDetailComponentProps {
    plant: PlantEntity;
    category?: CategoryEntity;
}

const PlantDetailComponent: React.FC<PlantDetailComponentProps> = ({ plant, category }) => {

    const {token} = useToken();

    return (
        <IonContent>
            {plant.userId === token.userId && <ToolsCard plant={plant} />}
            {category?.description && <DescriptionCard category={category} />}
            {(category?.superCategoryId || category?.wateringFrequency || category?.waterDemand)
                && <FactsCard plant={plant} category={category} />}
            <ImagesCard plant_id={plant.id} />
            <ChartsCard plant_id={plant.id} />
            <CommentsCard plant={plant} />
        </IonContent>
    );
};

export default PlantDetailComponent;
