import { IonButton } from '@ionic/react';
import { useEffect, useState } from 'react';

import './CategoryCreationForm.scss';
import { CategoryEntity } from '../data/Entities';
import { pushCategory } from '../data/DataApi';
import FormField from './FormField';
import HierarchicalSearchSelectModal from './HierarchicalSearchSelectModal';
import {
    IError,
    IField,
    getValues,
    resetValues,
    useCategoryCreationFields,
    useSuperCategorySelectionField,
    validateForm
} from '../utils/FormUtils';
import useLoadingState from '../utils/LoadingUtils';
import LoadingOverlayComponent from './utils/LoadingOverlayComponent';

interface CategoryCreationFormProps {
    categories?: CategoryEntity[];
    callback?: () => void;
}

export const CategoryCreationForm: React.FC<CategoryCreationFormProps>
    = ({ categories, callback }) => {

    const {isLoading, setIsLoading} = useLoadingState();

    const updateSuperCategoryField = (value: string|number) => {
        superCategoryField.input.state.value = value;
    }

    const createCategory = () => {
        const errors = validateForm([superCategoryField, ...fields]);
        setErrors(errors);
        
        if(errors.length === 0) {
            setIsLoading(true);
            pushCategory(getValues([superCategoryField, ...fields]))
                .then(() => resetValues(fields))
                .then(() => {
                    if(callback) {
                        callback();
                    }
                })
                .then(() => setIsLoading(false));
        }
    }

    const [superCategoryField, setSuperCategoryField] = useState<IField>(useSuperCategorySelectionField(
        updateSuperCategoryField));

    const fields: IField[] = useCategoryCreationFields(createCategory);
    const [errors, setErrors] = useState<IError[]>();

    useEffect(() => {
        let superCategoryField_copy = Object.assign({}, superCategoryField); // shallow copy the field
        superCategoryField_copy.input.options = // assign available options
            categories?.map(cat => {return({value: cat.id, label: cat.name, superValue: cat.superCategoryId})});
        setSuperCategoryField(superCategoryField_copy);
        // adding categories and superCategoyField as dependencies (not required) slows down the system
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {fields?.map((field, index) => {
                return(
                    <div className='formcontainer' key={index}>
                        <FormField field={field} errors={errors} key={index} />
                    </div>
                )
            })}
            <div className='formcontainer'>
                <HierarchicalSearchSelectModal field={superCategoryField} />
            </div>
            <div className='formcontainer'>
                <IonButton className='createbutton' onClick={createCategory}>Create Category</IonButton>
            </div>
            <LoadingOverlayComponent is_loading={isLoading} />
        </div>
    );
}
