import { IonButton, IonLabel, IonModal } from '@ionic/react';
import { ReactNode, useEffect, useState } from 'react';

import './PlantCreationForm.scss';
import {
    IError,
    IField,
    getValues,
    resetValues,
    useCategorySelectionField,
    usePlantCreationFields,
    validateForm
} from '../utils/FormUtils';
import FormField from './FormField';
import { fetchCategories, pushPlant } from '../data/DataApi';
import { CategoryCreationForm } from './CategoryCreationForm';
import { CategoryEntity } from '../data/Entities';
import useToken from '../utils/AuthenticationUtils';
import ModalToolbarComponent from './toolbars/ModalToolbarComponent';
import useModalState from '../utils/ModalUtils';
import useLoadingState from '../utils/LoadingUtils';
import LoadingOverlayComponent from './utils/LoadingOverlayComponent';
import HierarchicalSearchSelectModal from './HierarchicalSearchSelectModal';


export const PlantCreationForm: React.FC = () => {

    const {isLoading, setIsLoading} = useLoadingState();

    const updateCategoryField = (value: string|number) => {
        categoryField.input.state.value = value;
    }

    const createPlant = () => {
        const errors = validateForm([categoryField, ...fields]);
        setErrors(errors);

        if(errors.length === 0) {
            setIsLoading(true);
            pushPlant(getValues([categoryField, ...fields]), token.userId)
                .then(() => resetValues(fields))
                .then(() => setIsLoading(false));
        }
    }

    const[categoryField, setCategoryField]
        = useState<IField>(useCategorySelectionField(updateCategoryField));
    const fields = usePlantCreationFields(createPlant);
    const [errors, setErrors] = useState<IError[]>();

    const newCatModalState = useModalState('newCat');

    const [categories, setCategories] = useState<CategoryEntity[]>();

    const {token} = useToken();

    useEffect(() => {
        newCatModalState.closeOnBackButton();

        // Set the select options for the category selector
        fetchCategories().then((data) => {
            setCategories(data);
            let categoryField_copy = Object.assign({}, categoryField); // shallow copy the field
            categoryField_copy.input.options = // assign available options
                data?.map(cat => {return({value: cat.id, label: cat.name, superValue: cat.superCategoryId})});
            setCategoryField(categoryField_copy);
        });
    }, [newCatModalState.useEffectParam]);
    // Ignore the warning about missing dependency 'newCatModalState'
    // Leads to an endless loop of useEffect

    const categoryCreationHeader: ReactNode = (
        <>
            <div className='header'>
                <IonLabel color="medium">Select the category from the options below or </IonLabel>
                <IonButton onClick={() => newCatModalState.setIsOpen(true)}>
                    Create new Category
                </IonButton>
            </div>
            <IonModal isOpen={newCatModalState.isOpen} onDidDismiss={() => newCatModalState.setIsOpen(false)}>
                <ModalToolbarComponent title='Create Plant Category'
                    closeFunction={() => newCatModalState.setIsOpen(false)} />
                <CategoryCreationForm categories={categories}
                    callback={() => newCatModalState.setIsOpen(false)} />
            </IonModal>
        </>
    );

    return (
        <div className='plantcreation'>
            <div className='formcontainer'>
                <HierarchicalSearchSelectModal field={categoryField} header={categoryCreationHeader} />
            </div>
            <div className='formcontainer'>
                {fields?.map((field, index) => {
                    return(
                        <FormField field={field} errors={errors} key={index} />
                    )
                })}
            </div>
            <div className='formcontainer'>
                <IonButton className='createbutton' fill='solid' onClick={createPlant}>Create Plant</IonButton>
            </div>
            <LoadingOverlayComponent is_loading={isLoading} />
        </div>
    );
}
