import { IonButton, IonContent, IonIcon, IonImg, IonLabel } from "@ionic/react";
import { useState } from "react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import {
    cameraOutline,
    checkmarkCircleOutline,
    folderOpenOutline,
    removeCircleOutline
} from "ionicons/icons";

import "./CameraComponent.scss";
import ImageUtils from "../utils/ImageUtils";


interface CameraComponentProps {
    callback?: (image: Promise<Blob>) => Promise<void>;
}

const CameraComponent: React.FC<CameraComponentProps> = ({ callback }) => {

    const [imageUrl, setImageUrl] = useState<string|undefined>("");

    defineCustomElements(window); // required in order to use the webcam in the browser

    const capture = async(source: CameraSource) => {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source: source
        });
        setImageUrl(image.webPath);
    }

    const returnImage = async() => {
        if(imageUrl && callback) {
            callback(ImageUtils.getBase64FromImageUrl(imageUrl));
        }
    }

    return (
        <IonContent>
            {imageUrl
            ? <div>
            <div className="cameracomponent">
                <IonButton color="danger" onClick={() => setImageUrl(undefined)}>
                    <IonIcon aria-hidden="false" icon={removeCircleOutline} />
                    <IonLabel>Remove</IonLabel>
                </IonButton>
                <IonButton onClick={returnImage}>
                    <IonIcon aria-hidden="false" icon={checkmarkCircleOutline} />
                    <IonLabel>Upload</IonLabel>
                </IonButton>
            </div>
            <div className="cameracomponent">
                <IonImg src={imageUrl} />
            </div>
            </div>
            : <div className="cameracomponent">
                <IonButton onClick={() => capture(CameraSource.Camera)}>
                    <IonIcon aria-hidden="false" icon={cameraOutline} />
                    <IonLabel>Capture</IonLabel>
                </IonButton>
                <IonButton onClick={() => capture(CameraSource.Photos)}>
                    <IonIcon aria-hidden="false" icon={folderOpenOutline} />
                    <IonLabel>Choose from Files</IonLabel>
                </IonButton>
            </div> }
        </IonContent>
    );
};

export default CameraComponent;
