import useToken from '../../utils/AuthenticationUtils';
import './ToolbarComponent.scss';

import {
    IonButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar
} from '@ionic/react';

interface ToolbarProps {
    title: string;
}

const ToolbarComponent: React.FC<ToolbarProps> = ({ title }) => {
    const {token, setToken} = useToken();

    const logout = () => {
        setToken(undefined); // remove the token from local storage
        window.location.reload(); // reload page to handle new authentification situation
        // NOTE: not recommended to use window.location.reload() elsewhere (not best practice)
    }

    return (
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle color='light'>{title}</IonTitle>
                <IonButtons slot='end'>
                    <IonButton color='light' fill='outline' className='logout-button' onClick={logout} hidden={!token?.userId}>Logout</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
}

export default ToolbarComponent;
