import { IonContent, IonPage } from '@ionic/react';
import {CatalogType, PlantMarketContainer} from '../components/PlantMarketContainer';
import ToolbarComponent from '../components/toolbars/ToolbarComponent';
import './DiscoverPage.scss';

const DiscoverPage: React.FC = () => {
  return (
    <IonPage>
      <ToolbarComponent title="Discover"></ToolbarComponent>
      <IonContent>
        <PlantMarketContainer catalog_type={CatalogType.DISCOVER}></PlantMarketContainer>
      </IonContent>
    </IonPage>
  );
};

export default DiscoverPage;
