import {
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonIcon,
    IonModal,
    IonTitle
} from "@ionic/react";
import { cameraOutline, createOutline, trashOutline } from "ionicons/icons";
import { useEffect } from "react";

import "./ToolsCard.scss";
import { PlantEntity } from "../../data/Entities";
import CameraComponent from "../CameraComponent";
import { deletePlant, triggerScoreRefresh, uploadImage } from "../../data/DataApi";
import { PlantModificationForm } from "../PlantModificationForm";
import ModalToolbarComponent from "../toolbars/ModalToolbarComponent";
import useAlertState from "../../utils/AlertUtils";
import useLoadingState from "../../utils/LoadingUtils";
import useModalState from "../../utils/ModalUtils";
import LoadingOverlayComponent from "../utils/LoadingOverlayComponent";

interface ToolsCardProps {
    plant: PlantEntity;
}

const ToolsCard: React.FC<ToolsCardProps> = ({ plant }) => {

    const editModalState = useModalState('edit');
    const cameraModalState = useModalState('camera');
    const deletionAlertState = useAlertState('delete');
    const {isLoading, setIsLoading} = useLoadingState();

    const cameraCallback = async(image: Promise<Blob>): Promise<void> => {
        setIsLoading(true);
        uploadImage(await image, plant.id)
            .then(() => triggerScoreRefresh(plant.id))
            .then(() => cameraModalState.setIsOpen(false))
            .then(() => setIsLoading(false));
    }

    const confirmPlantDeletion = () => {
        setIsLoading(true);
        deletePlant(plant.id)
            .then(() => deletionAlertState.setIsOpen(false))
            .then(() => setIsLoading(false));
    }

    useEffect(() => {
        editModalState.closeOnBackButton();
        cameraModalState.closeOnBackButton();
        deletionAlertState.closeOnBackButton();
    }, [editModalState.useEffectParam, cameraModalState.useEffectParam, deletionAlertState.useEffectParam]);

    return (
        <>
            <IonCard>
                <IonCardHeader>
                    <IonTitle>
                        Modify
                    </IonTitle>
                </IonCardHeader>
                <IonCardContent>
                    <div className="tc-modify">
                    <IonButton class="toolscard" onClick={() => editModalState.setIsOpen(true)}>
                        <IonIcon color="light" aria-hidden="false" icon={createOutline} />
                    </IonButton>
                    <IonButton class="toolscard" onClick={() => cameraModalState.setIsOpen(true)}>
                        <IonIcon color="light" aria-hidden="false" icon={cameraOutline} />
                    </IonButton>
                    </div>
                    <div className="tc-delete">
                    <IonButton class="toolscard" color="danger" onClick={() => deletionAlertState.setIsOpen(true)}>
                        <IonIcon color="light" aria-hidden="false" icon={trashOutline} />
                    </IonButton>
                    </div>
                </IonCardContent>
            </IonCard>
            <IonModal isOpen={editModalState.isOpen} onDidDismiss={() => editModalState.setIsOpen(false)}>
                <ModalToolbarComponent title='Modify Plant' subtitle={plant.name}
                    closeFunction={() => editModalState.setIsOpen(false)} />
                <PlantModificationForm plant={plant} callback={() => editModalState.setIsOpen(false)} />
            </IonModal>
            <IonModal isOpen={cameraModalState.isOpen} onDidDismiss={() => cameraModalState.setIsOpen(false)}>
                <ModalToolbarComponent title="Take a Photo"
                    closeFunction={() => cameraModalState.setIsOpen(false)} />
                <CameraComponent callback={cameraCallback} />
            </IonModal>
            <IonAlert isOpen={deletionAlertState.isOpen}
                onDidDismiss={() => deletionAlertState.setIsOpen(false)}
                header={`Are you sure to delete plant ${plant.name}?`}
                buttons={[
                        {text: "Cancel", handler: () => deletionAlertState.setIsOpen(false)},
                        {text: "Delete", handler: () => confirmPlantDeletion()}
                    ]} />
            <LoadingOverlayComponent is_loading={isLoading} />
        </>
    );
};

export default ToolsCard;
