import { useState } from "react"
import { PlantEntity } from "../data/Entities";

// Thanks to https://github.com/alanmontgomery/ionic-react-login

export interface IField {
    id: string;
    label: string;
    required: boolean;
    input: IFieldInput;
    appearance?: IFieldAppearance;
}

interface IFieldInput {
    props: {
        type: string;
        placeholder: string;
        onIonChange?: (event: any) => void;
        onKeyDown?: (event: any) => void;
    };
    state: any;
    options?: IFieldOption[];
}

export interface IFieldOption {
    value: number | string;
    label: string;
    superValue?: number | string; // needed for hierarchical selection fields
    childs?: (number | string)[]; // needed for hierarchical selection fields
}

export interface IFieldAppearance {
    prefix?: string;
    suffix?: string;
}

export interface IError {
    id: string;
    message: string;
}

export const useFormInput = (initialValue: any) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = async (e: any) => {
        const tempValue = await e.currentTarget.value;
        setValue(tempValue);
    }

    return {
        value,
        reset: (newValue: any) => setValue(newValue),
        onIonChange: handleChange,
        onKeyUp: handleChange
    }
}

export const validateForm = (fields: IField[]): IError[] => {
    let errors: IError[] = [];
    fields.forEach((field: IField) => {
        if(field.required) {
            const fieldValue = field.input.state.value;
            if(fieldValue === "") {
                const error: IError = {
                    id: field.id,
                    message: `Please enter the ${field.label}`,
                };
                errors.push(error);
            }
        }
    });

    return errors;
}

export const getValues = (fields: IField[]): Map<string, any> => {
    const values = new Map<string, any>(fields.map(field => {
        return ([field.id, field.input.state.value]);
    }));
    return values;
}

export const resetValues = (fields: IField[]) => {
    fields.forEach((field: IField) => field.input.state.reset(""));
}

export const useLoginFields = (onKeyEnter: () => void): IField[] => {
    return [
        {
            id: "username",
            label: "Username",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "username",
                    onKeyDown: (event: any) => {
                        if(event.keyCode === 13)
                            onKeyEnter();
                    }
                },
                state: useFormInput("")
            }
        },
        {
            id: "password",
            label: "Password",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "******",
                    onKeyDown: (event: any) => {
                        if(event.keyCode === 13)
                            onKeyEnter();
                    }
                },
                state: useFormInput("")
            }
        }
    ];
}

export const useCategorySelectionField = (onChange: (event:any) => void, initVal: any = ""): IField => {
    return {
        id: "category",
        label: "Category",
        required: true,
        input: {
            props: {
                type: "select",
                placeholder: "Select Category",
                onIonChange: onChange
            },
            state: useFormInput(initVal),
        }
    }
}

export const useSuperCategorySelectionField = (onChange: (event: any) => void, initVal: any = ""): IField => {
    return {
        id: "super_category",
        label: "Super Category",
        required: true,
        input: {
            props: {
                type: "select",
                placeholder: "Select Super Category",
                onIonChange: onChange
            },
            state: useFormInput(initVal),
        }
    }
}

export const usePlantCreationFields = (onKeyEnter: () => void): IField[] => {
    return [
        {
            id: "name",
            label: "Plant Name",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "Name your plant",
                    onKeyDown: (event: any) => {
                        if(event.keyCode === 13)
                            onKeyEnter();
                    }
                },
                state: useFormInput(""),
            }
        }
    ]
}

export const usePlantModificationFields = (plant: PlantEntity, onKeyEnter: () => void): IField[] => {
    return [
        {
            id: "name",
            label: "Plant Name",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "Name your plant",
                    onKeyDown: (event: any) => {
                        if(event.keyCode === 13)
                            onKeyEnter();
                    }
                },
                state: useFormInput(plant.name),
            }
        }
    ]
}

export const useCategoryCreationFields = (onKeyEnter: () => void): IField[] => {
    return [
        {
            id: "name",
            label: "Category Name",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "Name of the new category",
                    onKeyDown: (event: any) => {
                        if(event.keyCode === 13)
                            onKeyEnter();
                    }
                },
                state: useFormInput("")
            }
        },
        {
            id: "description",
            label: "Category Description",
            required: false,
            input: {
                props: {
                    type: "text",
                    placeholder: "Describe the category",
                    onKeyDown: (event: any) => {
                        if(event.keyCode === 13)
                            onKeyEnter();
                    }
                },
                state: useFormInput("")
            }
        },
        {
            id: "water_demand",
            label: "Water Demand",
            required: false,
            input: {
                props: {
                    type: "number",
                    placeholder: "How much water is needed?",
                    onKeyDown: (event: any) => {
                        if(event.keyCode === 13)
                            onKeyEnter();
                    }
                },
                state: useFormInput(undefined)
            },
            appearance: {
                suffix: "ml"
            }
        },
        {
            id: "watering_frequency",
            label: "Watering Frequency",
            required: false,
            input: {
                props: {
                    type: "number",
                    placeholder: "How often does the plant need water?",
                    onKeyDown: (event: any) => {
                        if(event.keyCode === 13)
                            onKeyEnter();
                    }
                },
                state: useFormInput(undefined)
            },
            appearance: {
                prefix: "Every",
                suffix: "days"
            }
        }
    ]
}

export const useCommentCreationField = (onKeyEnter: () => void): IField => {
    return ({
        id: "comment",
        label: "Comment",
        required: true,
        input: {
            props: {
                type: "text",
                placeholder: "Type your comment here.",
                onKeyDown: (event: any) => {
                    if(event.keyCode === 13)
                        onKeyEnter();
                }
        },
            state: useFormInput("")
        }
    });
}

export const getField = (fields: IField[], fieldId: string): IField => {
    return fields.filter(field => (field.id === fieldId))[0];
}
