import './RouterComponent.scss';

import { Redirect, Route } from 'react-router-dom';
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { addCircleOutline, globeOutline, flowerOutline } from 'ionicons/icons';

import OverviewPage from '../../pages/OverviewPage';
import PlantCreationPage from '../../pages/PlantCreationPage';
import DiscoverPage from '../../pages/DiscoverPage';


interface RouterProps {
    overview: boolean;
    discover: boolean;
    creation: boolean;
}

const RouterComponent: React.FC<RouterProps> = ({ overview, discover, creation}) => {
  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/overview">
            <OverviewPage />
          </Route>
          <Route exact path="/discover">
            <DiscoverPage />
          </Route>
          <Route path="/plantcreation">
            <PlantCreationPage />
          </Route>
          {/* <Route exact path="/"> */}
            <Redirect to="/overview" />
          {/* </Route> */}
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="overview" href="/overview" disabled={!overview}>
            <IonIcon aria-hidden="true" icon={flowerOutline} />
            <IonLabel>Overview</IonLabel>
          </IonTabButton>
          <IonTabButton tab="discover" href="/discover" disabled={!discover}>
            <IonIcon aria-hidden="true" icon={globeOutline} />
            <IonLabel>Discover</IonLabel>
          </IonTabButton>
          <IonTabButton tab="plantcreation" href="/plantcreation" disabled={!creation}>
            <IonIcon aria-hidden="true" icon={addCircleOutline} />
            <IonLabel>Plant Creation</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  );
};

export default RouterComponent;
