import { useState } from "react";
import { useHistory, useLocation } from "react-router";

import URLUtils from "./URLUtils";

export default function useModalState(name: string) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const location = useLocation();
    const navigation = useHistory();
    const toggleModal = (open: boolean) => {
        if(open) {
            URLUtils.appendSearchParam(navigation, location, `${name}Modal`, 'true');
            setIsOpen(true);
        }
        else {
            if(location.location?.search?.endsWith(`${name}Modal=true`)) {
                navigation.go(-1); // navigate to the previous url (w/o the modal search param)
            }
            setIsOpen(false);
        }
    }
    const closeOnSearchParamChange = () => {
        if(!location.location?.search?.includes(`${name}Modal=true`)) {
            setIsOpen(false); // close modal when the search param changes
        }
    }

    return {
        isOpen,
        setIsOpen: toggleModal,
        closeOnBackButton: closeOnSearchParamChange,
        useEffectParam: location
    }
}
