import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { IFieldOption } from '../utils/FormUtils';

import './HierarchicalSelectOption.scss';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

interface HierarchicalSelectOptionProps {
    displayOption: IFieldOption | undefined;
    allOptions: IFieldOption[] | undefined;
    state: string | number;
    setState: (val: string|number) => void;
    header?: ReactNode;
}

export const HierarchicalSelectOption: React.FC<HierarchicalSelectOptionProps> = ({ displayOption, allOptions, state, setState }) => {
    const [nodeIsOpen, setNodeIsOpen] = useState<boolean>(displayOption?.value === 0);

    const [currentOption, setCurrentOption] = useState<IFieldOption>();

    const preprocessOption = (opt: IFieldOption | undefined): IFieldOption | undefined => {
        if(opt)
            opt.childs = allOptions?.filter(ao => ((ao.superValue === opt.value) && (ao.value !== 0))).map(ao => ao.value);
        return opt;
    }

    const select = (): void => {
        setNodeIsOpen(!nodeIsOpen);
        if(currentOption)
            setState(currentOption?.value);
    }

    useEffect(() => {
        setCurrentOption(preprocessOption(displayOption));
    }, [displayOption]);

    return (
        <>
            {currentOption && <div className='hso-item' key={currentOption.value}>
                {(currentOption.value !== 0) && <p color='primary'>&#8627;</p>}
                <IonItem color={(state === currentOption.value) ? "secondary" : "light"} key={currentOption.value} className='ion-activatable' onClick={select}>
                    <IonLabel>{currentOption.label}</IonLabel>
                    {currentOption.childs?.length !== 0 && !nodeIsOpen && <IonIcon icon={chevronDownOutline} /> }
                    {currentOption.childs?.length !== 0 && nodeIsOpen && <IonIcon icon={chevronUpOutline} /> }
                </IonItem>
            </div>}
                <div className='hso-childcontainer'>
                {nodeIsOpen &&
                    currentOption?.childs?.map((childVal => (
                        <div className='hso-child' key={childVal}>
                            <HierarchicalSelectOption displayOption={allOptions?.find(opt => (opt.value === childVal))} allOptions={allOptions} state={state} setState={setState} key={childVal} />
                        </div>
                    )))
                }
                </div>
        </>
    )
}

export default HierarchicalSelectOption;
