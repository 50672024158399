import { useState } from "react";

import { IField, getField } from "./FormUtils";
import { fetchUserByName } from "../data/DataApi";
import { UserEntity } from "../data/Entities";

// Reference: https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications

export interface IAuthToken {
    userId: number;
    username: string;
}

export default function useToken() {
    const TOKEN_KEY: string = 'token';

    const loadToken = (): IAuthToken => {
        const tokenString = localStorage.getItem(TOKEN_KEY);
        const userToken: IAuthToken = tokenString ? JSON.parse(tokenString) : null;
        return userToken;
    }

    const [token, setToken] = useState<IAuthToken>(loadToken());

    const saveToken = (userToken: IAuthToken|undefined) => {
        if(userToken) {
            localStorage.setItem(TOKEN_KEY, JSON.stringify(userToken));
            setToken(userToken);
        }
        else { // remove the token from storage if it is undefined/null (e.g., for logout)
            removeToken();
        }
    };

    const removeToken = () => {
        localStorage.removeItem(TOKEN_KEY);
    }

    return {
        token,
        setToken: saveToken,
    }
}

export async function verifyLoginCredentials(fields: IField[],
    setToken: (userToken: IAuthToken|undefined) => void) {
    const username: string = getField(fields, "username").input.state.value;
    const user: UserEntity = await fetchUserByName(username);
    // TODO: change this hardcoded verification to a server authentification
    if(user && getField(fields, "password").input.state.value === "3.1415") {
        setToken({userId: user.id, username: username});
        return true;
    }
    return false;
}
