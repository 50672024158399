import { IonCard, IonCardContent, IonCardHeader, IonText, IonTitle } from "@ionic/react";

import { CategoryEntity } from "../../data/Entities";
import "./DescriptionCard.scss";

interface DescriptionCardProps {
    category: CategoryEntity | undefined;
}

const DescriptionCard: React.FC<DescriptionCardProps> = ({ category }) => {

    return (
        <IonCard>
            <IonCardHeader>
                <IonTitle>
                    Description
                </IonTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonText>{category?.description}</IonText>
            </IonCardContent>
        </IonCard>
    );
};

export default DescriptionCard;
