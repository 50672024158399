import {
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';

import './ModalToolbarComponent.scss';

interface ModalToolbarProps {
    title?: string;
    subtitle?: string;
    closeFunction?: () => void;
}

const ModalToolbarComponent: React.FC<ModalToolbarProps> = ({ title, subtitle, closeFunction }) => {
    return (
        <IonHeader>
            <IonToolbar>
                <IonTitle>
                    {title}
                    {subtitle && <IonTitle className='mt-subtitle'>{subtitle}</IonTitle>}
                </IonTitle>
                {closeFunction &&
                    <IonButtons slot='end'>
                        <IonButton fill='solid' color="primary" onClick={closeFunction}>
                            <IonIcon color='light' aria-hidden="true" icon={closeCircleOutline} />
                        </IonButton>
                    </IonButtons>
                }
            </IonToolbar>
        </IonHeader>
    );
}

export default ModalToolbarComponent;
