import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonIcon, IonItem, IonList, IonRow, IonText, IonTitle } from "@ionic/react";
import { sendOutline } from "ionicons/icons";
import { useEffect, useState } from "react";

import "./CommentsCard.scss";
import { fetchComments, pushComment } from "../../data/DataApi";
import { CommentEntity, PlantEntity } from "../../data/Entities";
import FormField from "../FormField";
import { IError, getValues, resetValues, useCommentCreationField, validateForm } from "../../utils/FormUtils";
import useToken from "../../utils/AuthenticationUtils";
import useLoadingState from "../../utils/LoadingUtils";
import LoadingOverlayComponent from "../utils/LoadingOverlayComponent";
import LoadingIndicatorComponent from "../utils/LoadingIndicatorComponent";
import DateUtils from "../../utils/DateUtils";

interface CommentsCardProps {
    plant: PlantEntity;
}

const CommentsCard: React.FC<CommentsCardProps> = ({ plant }) => {

    const {isLoading, setIsLoading} = useLoadingState();

    const syncComments = (): Promise<void> => {
        return fetchComments(plant.id).then(data => setComments(data.reverse()));
    }

    const createComment = () => {
        const errors = validateForm([field]);
        setErrors(errors);

        if(errors.length === 0) {
            setIsLoading(true);
            pushComment(plant.id, getValues([field]), token.userId)
                .then(() => resetValues([field]))
                .then(() => {
                    syncComments();
                })
                .then(() => setIsLoading(false));
        }
    }

    const [comments, setComments] = useState<CommentEntity[]>();

    const field = useCommentCreationField(createComment);
    const [errors, setErrors] = useState<IError[]>();

    const {token} = useToken();

    useEffect(() => {
        syncComments();
    }, [plant])

    return (
        <>
            <IonCard>
                <IonCardHeader>
                    <IonTitle>
                        Comments
                    </IonTitle>
                </IonCardHeader>
                <IonCardContent>
                    { comments
                        ? <IonList class="cc-list">
                            {comments?.map((comment, index) => {
                                return(
                                    <IonItem class="cc-comment" key={index}>
                                        <IonCol>
                                            <IonText>{comment.text}</IonText>
                                            <IonRow class="cc-meta">
                                                <IonText class="cc-meta-name" color="medium">{comment.username}</IonText>
                                                <IonText class="cc-meta-timestamp" color="medium">{DateUtils.dateTimeString(comment.ts)}</IonText>
                                            </IonRow>
                                        </IonCol>
                                    </IonItem>
                                );
                            })}
                            {comments?.length === 0 &&
                                <IonItem>
                                    <IonText color="medium">No comments available yet.</IonText>
                                </IonItem>}
                            <IonItem class="cc-input">
                                <div className='formcontainer'>
                                    <FormField field={field} errors={errors} />
                                    <IonButton onClick={createComment}>
                                        <IonIcon color="light" aria-hidden="true" icon={sendOutline} />
                                    </IonButton>
                                </div>
                            </IonItem>
                        </IonList>
                        : <LoadingIndicatorComponent message="Loading comments..." /> }
                </IonCardContent>
            </IonCard>
            <LoadingOverlayComponent is_loading={isLoading} />
        </>
    );
};

export default CommentsCard;
